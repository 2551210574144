<template>
  <div class="privacy-policy small-intro" v-if="pageData">
      <div class="intro-section container">
          <div v-if="this.pageData.attributes.title">
              <h1 class="light" > {{this.pageData.attributes.title}}</h1>
              <p v-html="this.pageData.attributes.body.value">
              </p>
          </div>
      </div>
  </div>
</template>
<script>
export default {
  name:"external-scholarships",
  components: {
  },
  data:()=>{
      return {
          currentPageId:"c6d83556-440d-4ce5-aab5-0c740ab5d993",
          pageData:[],
      }
  },
  methods:{
  },
  mounted(){
      fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/page/${this.currentPageId}`, {
          method: "GET",
          mode: "cors",
      })
      .then((response) => {
          return response.json();
      })
      .then((json) => {
          this.pageData = json.data;

})
      .catch(function (error) {
          console.log("Request failed", error);
      });
  }
}
</script>
<style lang="scss" scoped>
</style>